/* eslint-disable camelcase */
import { navigate } from 'gatsby'
import { useEffect } from 'react'

import { NEW_SIGNUP_PATH } from '../../../constants'

const SignupForm = () => {
  // 💩 Force redirect from old sign up flow as a safeguard in case we missed an old endpoint in Contentful
  useEffect(() => {
    Promise.resolve(navigate(NEW_SIGNUP_PATH)).catch(console.error)
  }, [])

  return null
}

export default SignupForm
